// Modules
import React from "react";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import LoanOfficerSearch from "../../components/loan-officers/loan-officer-search";

const ContactLoanOfficer = ({ location }) => {
  const title = "Contact Loan Officer";
  const SEOData = {
    title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Looking for a loan officer in your area? The friendly team at WaFd Bank is here to help. Find yours here."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/contact-loan-officer"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };
  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      active: true,
      title: "Find a Loan Officer"
    }
  ];

  const loanOfficerSearchData = {
    location: { ...location }
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      {/* <Hero {...heroData} /> */}
      <Breadcrumb data={breadcrumbData} />
      <LoanOfficerSearch {...loanOfficerSearchData} />
    </SecondaryLanding>
  );
};

export default ContactLoanOfficer;
